import Typography from '../../atoms/typography'
import BHLink from '../../molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '../../molecules/button'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo'].includes(prop)
}

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: 0;
`

const Title = styled(Typography)`
	color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	line-height: 1.2;
	font-family: ${({ theme }) => theme.typography.fontFamilyAlternative};
	font-weight: 400;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(40)};
		line-height: 1.3;
	}
`
const Subtitle = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(14)};
	color: ${({ theme }) => theme.palette.common.white};
	margin-bottom: 20px;
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	}
`
const Description = styled('div')`
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	color: ${({ theme }) => theme.palette.common.white};
	line-height: var(--layout-line-height-medium);
	max-width: 400px;
	text-align: center;
	margin: 15px auto;
	.MuiTypography-root {
		line-height: inherit;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
		margin-top: 20px;
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: ${({ hasSiblings }) => (hasSiblings ? '40px' : '0')};
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
		margin-top: ${({ hasSiblings }) => (hasSiblings ? '48px' : '0')};
	}
`

const Link = styled(BHLink)`
	width: 100%;
`

const Button = styled(BHButton)`
	${({ theme }) => theme.breakpoints.down('md')} {
		display: flex;
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button }
