import genericConfigAggregator from '@bluheadless/ui/src/particles/rich-content/renderers/magento-pagebuilder/content-types/generic/config-aggregator'

// https://docs.4gift.com/style-genie/integration/web-component-solution
const DEFAULT_DOMAIN = 'https://stylegenie.4gift.com'

const configAggregator = (node) => {
	const configs = genericConfigAggregator(node)

	let styleGenieConfig = {
		domain: DEFAULT_DOMAIN,
		debug: false,
	}

	try {
		const customData = JSON.parse(configs?.customData)
		styleGenieConfig = {
			...styleGenieConfig,
			...customData,
		}
	} catch (e) {
		console.error(e)
	}

	return {
		...configs,
		styleGenieConfig,
	}
}

export default configAggregator
